import { useNavigate } from "react-router";
import arrowIcon from "../../img/arrow-left.svg";
import "./Contact.css";

const Contact = () => {
  const nav = useNavigate();

  return (
    <>
      <div class="container-order">
        <div className="page-title">
          <img
            src={arrowIcon}
            onClick={() => {
              nav("/");
            }}
          />
          <div className="card-block-element-title">
            <h3>Контакты</h3>
          </div>
        </div>

        <div className="contacts order-form">
          <div>
            <span>По коммерческим вопросам:</span>
            <a href={"tel:+79784514771"}>+7(978)45-14-771</a>
            <a href="mailto:partners@octobyte.ru">partners@octobyte.ru</a>
          </div>
          <div>
            <span>По техническим вопросам: </span>
            <a href={"tel:+79786121068"}>+7(978)61-21-068</a>
            <a href="mailto:support@octobyte.ru">support@spruton.shop</a>
          </div>
          <span className="bold">
            Мы всегда готовы ответить на ваши вопросы.
          </span>
        </div>
      </div>
    </>
  );
};

export default Contact;
