import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import arrowIcon from "../../img/arrow-left.svg";
import arrow2Icon from "../../img/arrow-right.svg";
import { useAppContext } from "../contextWrapper/AppContext";
import { calculateQuantity } from "../../utils/calculatePrice";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const OrderInfo = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const { userInfo } = useAppContext();

  const activeOrder = useMemo(
    () =>
      userInfo?.orders &&
      userInfo?.orders.find((order) => String(order.orderId) === id),
    [userInfo, id]
  );

  if (!activeOrder) {
    return null;
  }

  console.log(activeOrder);

  const totalCount = activeOrder.products.reduce((acc, product) => {
    acc +=
      parseInt(product.quantity * product.inBox) % product.inPackage !== 0
        ? Math.ceil(product.quantity * product.inBox)
        : parseInt(product.quantity * product.inBox);
    return acc;
  }, 0);

  const totalPrice = activeOrder.products.reduce((acc, product) => {
    acc +=
      parseInt(product.quantity * product.inBox) % product.inPackage !== 0
        ? Math.ceil(product.quantity * product.inBox) * product.price
        : parseInt(product.quantity * product.inBox) * product.price;
    return acc;
  }, 0);

  return (
    <>
      <div class="wrapper-content container-order">
        <div className="page-title">
          <img
            src={arrowIcon}
            onClick={() => {
              nav("/orders");
              window.Telegram.WebApp.HapticFeedback.impactOccurred("light"); // вибрация
            }}
          />
          <div className="card-block-element-title">
            <h3>Заказ №{id}</h3>
          </div>
        </div>

        <div>
          <div className="order-info">
            <h4>{new Date(activeOrder.orderDate * 1000).toLocaleString()}</h4>
            <div className="flex column gap-15">
              <div className="flex justify-between">
                <span>Товары, {totalCount} шт.</span>
                <span>{totalPrice} Р</span>
              </div>
              {/* скрыто <div className="flex justify-between">
              <span>Моя скидка</span>
              <span>??? р</span>
            </div> */}
              <div className="flex justify-between">
                <h3>Итого:</h3>
                <h3>{totalPrice} Р</h3>
              </div>
            </div>
          </div>

          <p className="gray-7d mt-15">Покупки</p>
          <div className="card-block-list orderInfo">
            {activeOrder &&
              activeOrder.products.map((product) => (
                <div
                  className="cart-item-row"
                  style={{ borderBottom: "2px solid #1c1c1c" }}
                  onClick={() => {
                    localStorage.setItem("product", JSON.stringify(product));
                    nav(`/product/${product.productID}`);
                  }}
                >
                  <div
                    className="cart-item-picture"
                    onClick={() => {
                      if (parseInt(product.inStock <= 0)) return;
                      localStorage.setItem(
                        "product",
                        JSON.stringify({
                          ...product,
                        })
                      );
                      nav("/product/" + product.productID);
                    }}
                  >
                    <img
                      src={`https://shop-api.toyseller.site/api/image/${product.productID}/${product.image}`}
                      alt="picture"
                    />
                  </div>
                  <div className="cart-item-data">
                    <div className="cart-item-label">
                      <h3>{product.article}</h3>
                      <div className="cart-item-caption">
                        <span>характеристики добавить</span>
                        <span>Кол-во: {calculateQuantity(product)} шт</span>
                      </div>
                      <h3 className="price-bottom">
                        {formatNumberWithSpaces(
                          parseInt(product.quantity * product.inBox) %
                            product.inPackage !==
                            0
                            ? Math.ceil(product.quantity * product.inBox) *
                                product.price
                            : parseInt(product.quantity * product.inBox) *
                                product.price
                        )}{" "}
                        ₽
                      </h3>
                    </div>
                    <div className="cart-right-block">
                      <img src={arrow2Icon} alt="arrow2Icon" />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderInfo;
