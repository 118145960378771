import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Catalog from "./views/Catalog";
import Cart from "./views/cart/Cart";
import Order from "./views/order/Order";
import Category from "./views/Category";
import Contact from "./views/contact/Contact";
import Terms from "./views/terms/Terms";
import Orders from "./views/ordersHistory/Orders";
import OrderInfo from "./views/ordersHistory/OrderInfo";
import Search from "./views/Search";
import { getUser } from "./api";
import { NewProduct } from "./views/product/NewProduct";
import { useTelegram } from "./views/telegramProvider/TelegramProvider";

const App = () => {
  const { webApp } = useTelegram();
  useEffect(() => {
    async function checkData() {
      const u = await getUser();

      if (!u || u?.error) {
        window.Telegram.WebApp.close();
      }
    }

    webApp?.requestFullscreen(); // во весь экран

    checkData();
  }, []);
  return (
    <BrowserRouter>
      <Toaster />
      <Routes>
        <Route path="/" element={<Catalog />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/order" element={<Order />} />
        <Route path="/product/:id" element={<NewProduct />} />
        <Route path="/category/:id" element={<Category />} />
        <Route path="/orderInfo/:id" element={<OrderInfo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/search" element={<Search />} />
        <Route
          path="/hook/payment/success"
          element={<Catalog paymentStatus={"success"} />}
        />
        <Route
          path="/hook/payment/fail"
          element={<Catalog paymentStatus={"error"} />}
        />
        <Route path="*" element={<Catalog />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
