import { useEffect } from "react";
import { useNavigate } from "react-router";
import { getUser } from "../../api";
import arrowIcon from "../../img/arrow-left.svg";
import { useAppContext } from "../contextWrapper/AppContext";
import "./Orders.css";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Orders = () => {
  const nav = useNavigate();

  const { userInfo, setUserInfo } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUser();
      localStorage.setItem("userInfo", JSON.stringify(user));
      setUserInfo(user);
    };

    fetchData();
  }, []);

  return (
    <>
      <div class="container-order">
        <div className="page-title">
          <img
            src={arrowIcon}
            onClick={() => {
              nav("/");
            }}
          />
          <div className="card-block-element-title">
            <h3>История заказов</h3>
          </div>
        </div>

        <div class="wrapper-content orders">
          {userInfo?.orders &&
            userInfo?.orders.map((order) => (
              <div
                class="cart-item-row"
                style={{ borderBottom: "2px solid #1c1c1c" }}
                onClick={() => {
                  localStorage.setItem("order", JSON.stringify(order));
                  nav(`/orderInfo/${order.orderId}`);
                  window.Telegram.WebApp.HapticFeedback.impactOccurred("light"); // вибрация
                }}
              >
                <div
                  class="cart-item-data"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <div className="cart-item-label">
                    <h3>Заказ #{order.orderId}</h3>
                    <div className="info-order">
                      <span className="gray-7d fs-14">Дата заказа: </span>
                      <span className="gray-7d fs-14">
                        {new Date(order.orderDate * 1000).toLocaleString()}
                      </span>
                      <br />
                      <span className="gray-7d fs-14">Кол-во товаров: </span>
                      <span className="gray-7d fs-14">
                        {order.products.length}
                      </span>
                    </div>
                    <span className="price-orders">
                      {formatNumberWithSpaces(parseInt(order.total))} ₽
                    </span>
                  </div>
                  <button
                    onClick={() => {
                      localStorage.setItem("order", JSON.stringify(order));
                      nav(`/orderInfo/${order.orderId}`);
                    }}
                  >
                    Подробнее
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Orders;
