import { useEffect, useState, useRef } from "react";
import { getProducts, getUser } from "../api";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import menuIcon from "../img/menu.svg";
import logo from "../img/logo.png";
import arrowIcon from "../img/arrow-left.svg";

import sortIcon from "../img/sort.svg";
import filterIcon from "../img/filter.svg";
import searchIcon from "../img/search_gray.svg";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Category = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const [query, setQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const [showTopButton, setShowTopButton] = useState(false);

  const [results, setResults] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [article, setArticle] = useState("");
  const [status, setStatus] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [pendingFilters, setPendingFilters] = useState({});
  const [pendingSortOrder, setPendingSortOrder] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [priceAccordionOpen, setPriceAccordionOpen] = useState(true);
  const [statusAccordionOpen, setStatusAccordionOpen] = useState(true);
  const [articleAccordionOpen, setArticleAccordionOpen] = useState(true);

  const menuContainersRefs = useRef([]);
  const navigationRef = useRef(null); // Ссылка на контейнер с навигацией

  const togglePriceAccordion = () => {
    setPriceAccordionOpen(!priceAccordionOpen);
  };

  const toggleStatusAccordion = () => {
    setStatusAccordionOpen(!statusAccordionOpen);
  };

  const toggleArticleAccordion = () => {
    setArticleAccordionOpen(!articleAccordionOpen);
  };

  const toggleFilter = () => {
    setFilterModal(!filterModal);
  };

  const toggleSort = () => {
    setSortModal(!sortModal);
  };

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCart(JSON.parse(localStorage.getItem("cart")));
    }

    async function fetchData() {
      const products = await getProducts();
      setProducts(products);
      console.log(products);
      if (searchParams.get("q")) {
        setQuery(searchParams.get("q"));
      }
      const user = await getUser();
      localStorage.setItem("user", JSON.stringify(user));
    }

    fetchData();

    window.scrollTo(0, 0);

    return () => {};
  }, []);

  useEffect(() => {
    const i = setInterval(() => {
      if (window.pageYOffset > 100) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    }, 100);

    return () => {
      clearInterval(i);
    };
  }, []);

  const applyFilters = () => {
    setQuery(pendingFilters.query || "");
    setPriceFrom(pendingFilters.priceFrom || "");
    setPriceTo(pendingFilters.priceTo || "");
    setArticle(pendingFilters.article || "");
    setStatus(pendingFilters.status || "");
    setFilterModal(false);
  };

  const applySort = () => {
    setSortOrder(pendingSortOrder);
    setSortModal(false);
  };

  useEffect(() => {
    const updatedProducts = products.map((category) => {
      const filteredProducts = category.products.filter((product) => {
        const matchesQuery =
          !query ||
          (product.categoryName &&
            product.categoryName.toLowerCase().includes(query.toLowerCase())) ||
          (product.article &&
            product.article.toLowerCase().includes(query.toLowerCase()));
        const matchesPrice =
          (!priceFrom || parseFloat(product.price) >= parseFloat(priceFrom)) &&
          (!priceTo || parseFloat(product.price) <= parseFloat(priceTo));
        const matchesArticle =
          !article ||
          (product.article &&
            product.article.toLowerCase().includes(article.toLowerCase()));
        const matchesStatus =
          status === "all" ||
          status === "" ||
          (status === "inStock" && product.inStock > 0) ||
          (status === "outOfStock" && product.inStock === 0);

        return matchesQuery && matchesPrice && matchesArticle && matchesStatus;
      });

      const sortedProducts = filteredProducts.sort((a, b) => {
        if (sortOrder === "priceAsc")
          return parseFloat(a.price) - parseFloat(b.price);
        if (sortOrder === "priceDesc")
          return parseFloat(b.price) - parseFloat(a.price);
        if (sortOrder === "newest") return parseInt(b.id) - parseInt(a.id);
        if (sortOrder === "oldest") return parseInt(a.id) - parseInt(b.id);
        if (sortOrder === "bestsellers")
          return parseInt(a.inBox) - parseInt(b.inBox);
      });

      const finalProducts =
        filteredProducts.length === 0 ? category.products : sortedProducts;

      return { ...category, products: finalProducts };
    });

    setResults(updatedProducts);
    console.log(results);
  }, [query, priceFrom, priceTo, article, status, sortOrder, products]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));

    let price = 0;
    cart.forEach((product) => {
      price += product.price * product.quantity * product.inBox;
    });
    setTotalPrice(parseInt(price));

    if (price > 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [cart]);

  const goToCart = () => {
    nav("/cart");
  };

  return (
    <>
      {filterModal && (
        <div className="modal-container">
          <div className="modal-overlay"></div>
          <div className="modal-content">
            <form>
              <div>
                <div>
                  <button className="close-button" onClick={toggleFilter}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        fill="currentColor"
                        d="M18 6.41L16.59 5 12 9.59 7.41 5 6 6.41 10.59 11 6 15.59 7.41 17 12 12.41 16.59 17 18 15.59 13.41 11z"
                      />
                    </svg>
                  </button>
                  <h3>Фильтр</h3>
                </div>

                <div className="accordion">
                  <div
                    className="accordion-header"
                    onClick={toggleStatusAccordion}
                  >
                    <label>Статус продажи</label>
                    <svg
                      className={`arrow ${priceAccordionOpen ? "open" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 15.414l6.293-6.293 1.414 1.414-7.707 7.707-7.707-7.707 1.414-1.414z"
                      />
                    </svg>
                  </div>
                  {statusAccordionOpen && (
                    <div className="accordion-content">
                      <div className="form-group-radio">
                        <input
                          type="radio"
                          value="all"
                          id="all"
                          checked={pendingFilters.status === "all"}
                          onChange={() =>
                            setPendingFilters({
                              ...pendingFilters,
                              status: "all",
                            })
                          }
                        />
                        <label htmlFor="all">Все товары</label>
                        <input
                          type="radio"
                          value="inStock"
                          id="inStock"
                          checked={pendingFilters.status === "inStock"}
                          onChange={() =>
                            setPendingFilters({
                              ...pendingFilters,
                              status: "inStock",
                            })
                          }
                        />
                        <label htmlFor="inStock">Есть в наличии</label>
                        <input
                          type="radio"
                          value="outOfStock"
                          id="outOfStock"
                          checked={pendingFilters.status === "outOfStock"}
                          onChange={() =>
                            setPendingFilters({
                              ...pendingFilters,
                              status: "outOfStock",
                            })
                          }
                        />
                        <label htmlFor="outOfStock">Нет в наличии</label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="accordion">
                  <div
                    className="accordion-header"
                    onClick={togglePriceAccordion}
                  >
                    <label>Цена</label>
                    <svg
                      className={`arrow ${priceAccordionOpen ? "open" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 15.414l6.293-6.293 1.414 1.414-7.707 7.707-7.707-7.707 1.414-1.414z"
                      />
                    </svg>
                  </div>
                  {priceAccordionOpen && (
                    <div className="accordion-content">
                      <div className="form-group">
                        <input
                          type="number"
                          className="formInput"
                          placeholder="От"
                          value={pendingFilters.priceFrom || ""}
                          onChange={(e) =>
                            setPendingFilters({
                              ...pendingFilters,
                              priceFrom: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="number"
                          className="formInput"
                          placeholder="До"
                          value={pendingFilters.priceTo || ""}
                          onChange={(e) =>
                            setPendingFilters({
                              ...pendingFilters,
                              priceTo: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="accordion">
                  <div
                    className="accordion-header"
                    onClick={toggleArticleAccordion}
                  >
                    <label>Артикул</label>
                    <svg
                      className={`arrow ${priceAccordionOpen ? "open" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 15.414l6.293-6.293 1.414 1.414-7.707 7.707-7.707-7.707 1.414-1.414z"
                      />
                    </svg>
                  </div>
                  {articleAccordionOpen && (
                    <div className="accordion-content">
                      <div className="form-group">
                        <div className="search-article">
                          <img src={searchIcon} alt="" />
                          <input
                            type="text"
                            className="formInput"
                            placeholder="Поиск по значениям"
                            value={pendingFilters.article || ""}
                            onChange={(e) =>
                              setPendingFilters({
                                ...pendingFilters,
                                article: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <button
                type="button"
                className="formButton"
                onClick={applyFilters}
              >
                Применить
              </button>
            </form>
          </div>
        </div>
      )}
      {sortModal && (
        <div className="modal-container">
          <div className="modal-overlay"></div>
          <div className="modal-content">
            <form>
              <div>
                <div>
                  <button className="close-button" onClick={toggleSort}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        fill="currentColor"
                        d="M18 6.41L16.59 5 12 9.59 7.41 5 6 6.41 10.59 11 6 15.59 7.41 17 12 12.41 16.59 17 18 15.59 13.41 11z"
                      />
                    </svg>
                  </button>
                  <h3>Сортировка</h3>
                </div>

                <div className="form-group-radio">
                  <input
                    type="radio"
                    value="priceAsc"
                    name="sortOrder"
                    id="priceAsc"
                    checked={pendingSortOrder === "priceAsc"}
                    onChange={() => setPendingSortOrder("priceAsc")}
                  />
                  <label htmlFor="priceAsc">Цене (по возрастанию)</label>
                  <input
                    type="radio"
                    value="priceDesc"
                    name="sortOrder"
                    id="priceDesc"
                    checked={pendingSortOrder === "priceDesc"}
                    onChange={() => setPendingSortOrder("priceDesc")}
                  />
                  <label htmlFor="priceDesc">Цене (по убыванию)</label>
                  <input
                    type="radio"
                    value="bestsellers"
                    name="sortOrder"
                    id="bestsellers"
                    checked={pendingSortOrder === "bestsellers"}
                    onChange={() => setPendingSortOrder("bestsellers")}
                  />
                  <label htmlFor="bestsellers">Бестселлеры</label>
                  <input
                    type="radio"
                    value="newest"
                    name="sortOrder"
                    id="newest"
                    checked={pendingSortOrder === "newest"}
                    onChange={() => setPendingSortOrder("newest")}
                  />
                  <label htmlFor="newest">Недавно добавленные</label>
                  <input
                    type="radio"
                    value="oldest"
                    name="sortOrder"
                    id="oldest"
                    checked={pendingSortOrder === "oldest"}
                    onChange={() => setPendingSortOrder("oldest")}
                  />
                  <label htmlFor="oldest">Давно добавленные</label>
                </div>
              </div>
              <button type="button" className="formButton" onClick={applySort}>
                Применить
              </button>
            </form>
          </div>
        </div>
      )}
      <div className="container">
        <div className="order-form small">
          <div className="container">
            {results
              .filter((_, i) => i == id)
              .map((category, index) => {
                return (
                  <>
                    <div className="title2" id={`category${index}`}>
                      <img
                        src={arrowIcon}
                        onClick={() => {
                          nav("/");
                        }}
                      />
                      {category.categoryName}
                    </div>

                    <div className="form-search">
                      <div className="form-group-section">
                        <div className="form-group">
                          <input
                            type="text"
                            className="formInput"
                            placeholder="Поиск..."
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="search-modals">
                        <div className="form-filter">
                          <button onClick={toggleFilter}>
                            <img src={filterIcon} alt="filter icon" />
                            <span>Фильтры</span>
                          </button>
                        </div>
                        <div className="form-sort">
                          <button onClick={toggleSort}>
                            <img src={sortIcon} alt="sort icon" />
                            <span>Сортировка</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="menuContainer2"
                      ref={(ref) => (menuContainersRefs.current[index] = ref)}
                      key={index}
                    >
                      {category.products.map((product) => {
                        const inCart = cart.find(
                          (_product) => _product.id == product.id
                        );
                        if (!product.price || parseInt(product.price) === 0)
                          return;
                        if (!product.inStock || parseInt(product.inStock) === 0)
                          return;
                        /*if (!product.inStock || parseInt(product.inStock) === 0)
                      return (
                        <div className="menuItem">
                          <div className="info">
                            <img
                              src={`https://shop-api.toyseller.site/api/image/${product.id}/${product.image}`}
                              alt="food"
                              class="picture"
                            />
                            <div class="name">{product.article}</div>
                            <div class="weight">
                              PM3: {product.inBox} шт
                              <br />
                            </div>
                          </div>
                          <div class="price">Нет в наличии</div>
                        </div>
                      );*/
                        return (
                          <div className="menuItem">
                            <div className="info">
                              <img
                                onClick={() => {
                                  localStorage.setItem(
                                    "product",
                                    JSON.stringify({
                                      ...product,
                                      inCart: inCart ? inCart.quantity : 0,
                                    })
                                  );
                                  nav(`/product/${product.id}`);
                                }}
                                src={`https://shop-api.toyseller.site/api/image/${product.id}/${product.image}`}
                                alt="food"
                                class="picture"
                              />
                              <div class="name">{product.article}</div>
                              <div class="weight">
                                PM3: {product.inBox} шт
                                <br />
                              </div>
                            </div>
                            {inCart ? (
                              <div class="add catalog_counter">
                                <div
                                  class="cic-minus"
                                  onClick={() => {
                                    let minusAmount = 1;
                                    if (
                                      parseInt(product.inBox) >=
                                      parseInt(inCart.quantity * product.inBox)
                                    ) {
                                      minusAmount =
                                        1 /
                                        (parseInt(product.inBox) /
                                          parseInt(product.inPackage));
                                    } else if (
                                      parseInt(product.inBox) +
                                        parseInt(product.inTheBox) <=
                                      parseInt(inCart.quantity * product.inBox)
                                    ) {
                                      minusAmount =
                                        parseInt(product.inTheBox) /
                                        parseInt(product.inBox);
                                    }

                                    const newQuantity =
                                      inCart.quantity.toFixed(3) >
                                      minusAmount.toFixed(3)
                                        ? parseFloat(
                                            inCart.quantity - minusAmount
                                          )
                                        : 0;

                                    if (newQuantity > 0) {
                                      setCart(
                                        cart.map((_product) => {
                                          if (_product.id === product.id) {
                                            return {
                                              ..._product,
                                              quantity: newQuantity,
                                            };
                                          }
                                          return _product;
                                        })
                                      );
                                    } else {
                                      setCart(
                                        cart.filter(
                                          (_product) =>
                                            _product.id != product.id
                                        )
                                      );
                                    }
                                  }}
                                >
                                  <svg
                                    class="svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0"
                                    y="0"
                                    viewBox="0 0 341.333 341.333"
                                    style={{
                                      "enable-background": "new 0 0 512 512",
                                    }}
                                    xmlSpace="preserve"
                                  >
                                    <rect
                                      y="149.333"
                                      width="341.333"
                                      height="42.667"
                                      fill="#f6f6f6"
                                    ></rect>
                                  </svg>
                                </div>
                                <div class="amount">
                                  {parseInt(inCart.quantity * product.inBox) %
                                    product.inPackage !==
                                  0
                                    ? Math.ceil(inCart.quantity * product.inBox)
                                    : parseInt(inCart.quantity * product.inBox)}
                                </div>
                                <div
                                  class="cic-plus"
                                  onClick={() => {
                                    if (
                                      inCart.quantity <
                                      product.inStock *
                                        (parseInt(product.inTheBox) /
                                          parseInt(product.inBox))
                                    ) {
                                      let incrementAmount = 1;
                                      if (
                                        parseInt(product.inBox) >
                                        parseInt(
                                          inCart.quantity * product.inBox
                                        )
                                      ) {
                                        incrementAmount =
                                          1 /
                                          (parseInt(product.inBox) /
                                            parseInt(product.inPackage)); //parseInt(product.inPackage)
                                      }
                                      setCart(
                                        cart.map((_product) => {
                                          if (_product.id === product.id) {
                                            return {
                                              ..._product,
                                              quantity: parseFloat(
                                                (
                                                  _product.quantity +
                                                  incrementAmount
                                                ).toFixed(2)
                                              ),
                                            };
                                          }
                                          return _product;
                                        })
                                      );
                                    }
                                  }}
                                >
                                  <svg
                                    class="svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0"
                                    y="0"
                                    viewBox="0 0 341.4 341.4"
                                    style={{
                                      "enable-background": "new 0 0 512 512",
                                    }}
                                    xmlSpace="preserve"
                                  >
                                    <polygon
                                      points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192 341.4,149.4"
                                      fill="#f6f6f6"
                                      class=""
                                    ></polygon>
                                  </svg>
                                </div>
                              </div>
                            ) : (
                              <div
                                class="price"
                                onClick={() => {
                                  localStorage.setItem(
                                    "product",
                                    JSON.stringify({
                                      ...product,
                                    })
                                  );
                                  nav("/product/" + product.id);
                                }}
                              >
                                {formatNumberWithSpaces(Number(product.price))}{" "}
                                ₽
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}
            {/* кнопка вверх */}
            {showTopButton > 0 && (
              <div
                style={{
                  position: "fixed",
                  bottom: 20,
                  right: 20,
                  width: 50,
                  height: 50,
                  borderRadius: 25,
                  backgroundColor: "#2a2a2a",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  zIndex: 1000,
                  display: "flex",
                }}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.23191 10.6401C5.58591 11.0641 6.21591 11.1221 6.63991 10.7681L10.9999 7.13512V19.0001C10.9999 19.5521 11.4479 20.0001 11.9999 20.0001C12.5519 20.0001 12.9999 19.5521 12.9999 19.0001V7.13512L17.3599 10.7681C17.7849 11.1221 18.4149 11.0641 18.7679 10.6401C19.1219 10.2161 19.0649 9.58512 18.6399 9.23212L12.6399 4.23212C12.5929 4.19212 12.5369 4.17312 12.4849 4.14312C12.4439 4.11912 12.4079 4.09112 12.3629 4.07312C12.2459 4.02712 12.1239 4.00012 11.9999 4.00012C11.8759 4.00012 11.7539 4.02712 11.6369 4.07312C11.5919 4.09112 11.5559 4.11912 11.5149 4.14312C11.4629 4.17312 11.4069 4.19212 11.3599 4.23212L5.35991 9.23212C4.93591 9.58512 4.87791 10.2161 5.23191 10.6401Z"
                    fill="#fff"
                  />
                  <mask
                    id="mask0_7_4676"
                    style={{ "mask-type": "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="5"
                    y="4"
                    width="15"
                    height="17"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.23191 10.6401C5.58591 11.0641 6.21591 11.1221 6.63991 10.7681L10.9999 7.13512V19.0001C10.9999 19.5521 11.4479 20.0001 11.9999 20.0001C12.5519 20.0001 12.9999 19.5521 12.9999 19.0001V7.13512L17.3599 10.7681C17.7849 11.1221 18.4149 11.0641 18.7679 10.6401C19.1219 10.2161 19.0649 9.58512 18.6399 9.23212L12.6399 4.23212C12.5929 4.19212 12.5369 4.17312 12.4849 4.14312C12.4439 4.11912 12.4079 4.09112 12.3629 4.07312C12.2459 4.02712 12.1239 4.00012 11.9999 4.00012C11.8759 4.00012 11.7539 4.02712 11.6369 4.07312C11.5919 4.09112 11.5559 4.11912 11.5149 4.14312C11.4629 4.17312 11.4069 4.19212 11.3599 4.23212L5.35991 9.23212C4.93591 9.58512 4.87791 10.2161 5.23191 10.6401Z"
                      fill="#fff"
                    />
                  </mask>
                  <g mask="url(#mask0_7_4676)">
                    <rect width="24" height="24" fill="#fff" />
                  </g>
                </svg>
              </div>
            )}
          </div>
          <footer
            className="footer"
            // style={{
            //   marginBottom: 120,
            // }}
          >
            <img src={logo} className="logoIcon" alt="logoIcon" />
            <p className="footer-text">
              Мы выбрали{" "}
              <a href="https://spruton.shop/" target="_blank" rel="noreferrer">
                СПРУТОН МАРКЕТ
              </a>{" "}
              для создания магазина
            </p>
            <div
              className="phone"
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
                gap: "10px",
                alignItems: "center",
              }}
            >
              Техническая поддержка:
              <a
                onClick={() => {
                  window.open("tel:+79786121068", "_blank");
                }}
              >
                +79786121068
              </a>
              <a
                onClick={() => {
                  window.open("mailto:support@spruton.shop", "_blank");
                }}
              >
                support@spruton.shop
              </a>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Category;
