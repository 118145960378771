import { YMInitializer } from "react-yandex-metrika";
import App from "./App";
import { TelegramProvider } from "./views/telegramProvider/TelegramProvider";
import { AppProvider } from "./views/contextWrapper/AppContext";

export const AppWrapper = ({ children }) => {
  return (
    <TelegramProvider>
      <AppProvider>
        <App />
        <YMInitializer accounts={[97574100]} />
      </AppProvider>
    </TelegramProvider>
  );
};
