import { useState, useEffect } from "react";
import "./ButtonBlock.css";
import { calculatePrice } from "../../utils/calculatePrice";
import { useNavigate } from "react-router";
import { useAppContext } from "../contextWrapper/AppContext";

export const ButtonBlock = ({ product }) => {
  const { cart, setCart } = useAppContext();
  const [inCart, setInCart] = useState({});
  const price = calculatePrice(inCart, product);
  const nav = useNavigate();

  const cardStorage = localStorage.getItem("cart");
  const actualCart = JSON.parse(cardStorage);
  useEffect(() => {
    if (cart.length === 0 && actualCart.length > 0) {
      setInCart({ ...actualCart.find((item) => item.id === product.id) });
    }
  }, [cart]);

  return (
    <div class="product-count-wrap">
      <div class="pcw-btn-group">
        <div class="price-product-card">
          <span class="js-product-price">{price}</span>
           ₽
        </div>
        {inCart.quantity > 0 ? (
          <div class="pcw-count-wrap">
            <div
              class="pcw-minus"
              onClick={() => {
                if (
                  inCart.quantity <=
                  parseInt(product.inStock) *
                    (parseInt(product.inTheBox) / parseInt(product.inBox))
                ) {
                  let minusAmount = 1;
                  if (
                    parseInt(product.inBox) >=
                    parseInt(inCart.quantity * product.inBox)
                  ) {
                    minusAmount =
                      1 /
                      (parseInt(product.inBox) / parseInt(product.inPackage));
                  } else if (
                    parseInt(product.inBox) + parseInt(product.inTheBox) <=
                    parseInt(inCart.quantity * product.inBox)
                  ) {
                    minusAmount =
                      parseInt(product.inTheBox) / parseInt(product.inBox);
                  }

                  const newQuantity =
                    inCart.quantity.toFixed(3) > minusAmount.toFixed(3)
                      ? parseFloat(inCart.quantity - minusAmount)
                      : 0;

                  if (newQuantity > 0) {
                    const newCart = cart.map((item) => {
                      if (item.id === product.id) {
                        item.quantity = newQuantity;
                      }
                      return item;
                    });
                    setCart(newCart);
                    setInCart({
                      ...inCart,
                      quantity: newQuantity,
                    });
                  } else {
                    const newCart = cart.filter(
                      (item) => item.id !== product.id
                    );
                    setCart(newCart);
                    setInCart({});
                  }
                } else {
                  const newCart = cart.filter((item) => item.id !== product.id);
                  setCart(newCart);
                  setInCart({});
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0"
                y="0"
                viewBox="0 0 341.333 341.333"
                style={{ "enable-background": "new 0 0 512 512" }}
                xmlSpace="preserve"
                class=""
              >
                <rect
                  y="149.333"
                  width="341.333"
                  height="42.667"
                  fill="#ffffff"
                ></rect>
              </svg>
            </div>
            <div class="pcw-count">
              {parseInt(inCart.quantity * product.inBox) % product.inPackage !==
              0
                ? Math.ceil(inCart.quantity * product.inBox)
                : parseInt(inCart.quantity * product.inBox)}
            </div>
            <div
              class="pcw-plus"
              onClick={() => {
                if (
                  inCart.quantity <
                  product.inStock *
                    (parseInt(product.inTheBox) / parseInt(product.inBox))
                ) {
                  let incrementAmount = 1;
                  if (
                    parseInt(product.inBox) >
                    parseInt(inCart.quantity * product.inBox)
                  ) {
                    incrementAmount =
                      1 /
                      (parseInt(product.inBox) / parseInt(product.inPackage)); //parseInt(product.inPackage)
                  }

                  const newCart = cart.map((item) => {
                    if (item.id === product.id) {
                      item.quantity += incrementAmount;
                    }
                    return item;
                  });
                  setCart(newCart);
                  setInCart({
                    ...inCart,
                    quantity: inCart.quantity + incrementAmount,
                  });
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0"
                y="0"
                viewBox="0 0 341.4 341.4"
                style={{ "enable-background": "new 0 0 512 512" }}
                xmlSpace="preserve"
                class=""
              >
                <polygon
                  points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192 341.4,149.4 "
                  fill="#ffffff"
                  class=""
                ></polygon>
              </svg>
            </div>
          </div>
        ) : (
          <>
            <div
              class="gto-btn"
              onClick={() => {
                if (inCart.quantity > 0) {
                  nav("/cart");
                } else {
                  const newCart = [
                    ...cart,
                    {
                      ...product,
                      quantity: 1,
                    },
                  ];
                  setCart(newCart);
                  setInCart({
                    ...inCart,
                    quantity: 1,
                  });
                }
              }}
            >
              <a href="#" class="js-add-cart">
                Добавить
              </a>
            </div>
          </>
        )}
        {inCart.quantity > 0 ? (
          <div
            class="gto-btn"
            onClick={() => {
              nav("/cart");
            }}
          >
            <a href="#" class="js-add-cart">
              В корзину
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
