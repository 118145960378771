import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import arrowIcon from "../../img/arrow-left.svg";
import cartIcon from "../../img/cart.svg";
import { getDeclination } from "../../utils/getDeclination";
import { useAppContext } from "../contextWrapper/AppContext";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Cart = () => {
  const nav = useNavigate();
  const { cart, setCart } = useAppContext();
  const [totalPrice, setTotalPrice] = useState(0);

  const deleteCart = async () => {
    await localStorage.setItem("cart", JSON.stringify([]));
    setCart([]);
    nav("/");
  };

  useEffect(() => {
    let price = 0;
    cart.forEach((product) => {
      price += product.price * product.quantity * product.inBox;
    });
    setTotalPrice(parseInt(price));
  }, [cart]);

  const goToOrder = () => {
    nav("/order");
    window.Telegram.WebApp.HapticFeedback.impactOccurred("light"); // вибрация
  };

  return (
    <>
      <div className="container-order">
        <div className="page-title">
          <img
            src={arrowIcon}
            onClick={() => {
              nav("/");
            }}
          />
          <div className="card-block-element-title">
            <h3>Корзина</h3>
            <span className="gray-7d">
              {getDeclination(cart.length, ["товар", "товара", "товаров"])}
            </span>
          </div>
          <button className="cart-empty" onClick={() => deleteCart()}>
            <img src={cartIcon} alt="cartIcon" />
          </button>
        </div>
        <div className="cart1">
          {cart.map((product, index) => (
            <div className="cart-item-row" style={{ position: "relative" }}>
              <img
                src={`/close.svg`}
                alt="picture"
                className="close_ic"
                onClick={() => {
                  setCart(cart.filter((i) => i.id !== product.id));
                }}
              />
              <div
                className="cart-item-picture"
                onClick={() => {
                  if (parseInt(product.inStock <= 0)) return;
                  localStorage.setItem(
                    "product",
                    JSON.stringify({
                      ...product,
                    })
                  );
                  nav("/product/" + product.id);
                }}
              >
                <img
                  src={`https://shop-api.toyseller.site/api/image/${product.id}/${product.image}`}
                  alt="picture"
                />
              </div>
              <div className="cart-item-data">
                <div className="flex gap-10 column width-100">
                  <h3
                    className="cart-item-label"
                    onClick={() => {
                      if (parseInt(product.inStock <= 0)) return;
                      localStorage.setItem(
                        "product",
                        JSON.stringify({
                          ...product,
                        })
                      );
                      nav("/product/" + product.id);
                    }}
                  >
                    {product.article}
                  </h3>
                  <div className="flex column">
                    {/* <span className="cart-item-caption gray-7d">???</span> */}
                    <span className="cart-item-caption gray-7d">
                      PM3: {product.inBox} шт
                    </span>
                  </div>
                </div>
                <div className="flex justify-between align-center mt-20 width-100">
                  <div className="flex gap-5 align-end">
                    <span className="price-green-label">{product.price} P</span>
                    {/* <span className="old-gray-price">{product.price} P</span> */}
                  </div>
                  {Number(product.inStock) > 0 ? (
                    <>
                      <div
                        className="cart-item-counter"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div
                          className="cic-minus"
                          onClick={() => {
                            let minusAmount = 1;
                            if (
                              parseInt(product.inBox) >=
                              parseInt(product.quantity * product.inBox)
                            ) {
                              minusAmount =
                                1 /
                                (parseInt(product.inBox) /
                                  parseInt(product.inPackage));
                            } else if (
                              parseInt(product.inBox) +
                                parseInt(product.inTheBox) <=
                              parseInt(product.quantity * product.inBox)
                            ) {
                              minusAmount =
                                parseInt(product.inTheBox) /
                                parseInt(product.inBox);
                            }

                            const newQuantity =
                              product.quantity.toFixed(3) >
                              minusAmount.toFixed(3)
                                ? parseFloat(product.quantity - minusAmount)
                                : 0;

                            if (newQuantity > 0) {
                              setCart(
                                cart.map((_product) => {
                                  if (_product.id === product.id) {
                                    return {
                                      ..._product,
                                      quantity: newQuantity,
                                    };
                                  }
                                  return _product;
                                })
                              );
                            } else {
                              setCart(
                                cart.filter(
                                  (_product) => _product.id != product.id
                                )
                              );
                            }
                          }}
                        >
                          <svg
                            className="svg"
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0"
                            y="0"
                            viewBox="0 0 341.333 341.333"
                            style={{
                              "enable-background": "new 0 0 512 512",
                            }}
                            xmlSpace="preserve"
                          >
                            <rect
                              y="149.333"
                              width="341.333"
                              height="42.667"
                              fill="#f6f6f6"
                            ></rect>
                          </svg>
                        </div>
                        <div className="cic-count">
                          {parseInt(product.quantity * product.inBox) %
                            product.inPackage !==
                          0
                            ? Math.ceil(product.quantity * product.inBox)
                            : parseInt(product.quantity * product.inBox)}
                        </div>
                        <div
                          className="cic-plus"
                          onClick={() => {
                            if (
                              product.quantity <
                              product.inStock *
                                (parseInt(product.inTheBox) /
                                  parseInt(product.inBox))
                            ) {
                              let incrementAmount = 1;
                              if (
                                parseInt(product.inBox) >
                                parseInt(product.quantity * product.inBox)
                              ) {
                                incrementAmount =
                                  1 /
                                  (parseInt(product.inBox) /
                                    parseInt(product.inPackage)); //parseInt(product.inPackage)
                              }
                              setCart(
                                cart.map((_product) => {
                                  if (_product.id === product.id) {
                                    return {
                                      ..._product,
                                      quantity: parseFloat(
                                        (
                                          _product.quantity + incrementAmount
                                        ).toFixed(2)
                                      ),
                                    };
                                  }
                                  return _product;
                                })
                              );
                            }
                          }}
                        >
                          <svg
                            className="svg"
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0"
                            y="0"
                            viewBox="0 0 341.4 341.4"
                            style={{
                              "enable-background": "new 0 0 512 512",
                            }}
                            xmlSpace="preserve"
                          >
                            <polygon
                              points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192 341.4,149.4"
                              fill="#f6f6f6"
                              className=""
                            ></polygon>
                          </svg>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="cart-item-counter notqqq">
                      <div>Нет в наличии</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="button-block">
          {totalPrice == 0 ? (
            <center>
              <p>Ваша корзина пуста</p>
            </center>
          ) : (
            <></>
          )}
          {totalPrice > 0 ? (
            <div className="bottom-card-button">
              <button className="add-button full" onClick={goToOrder}>
                К оформлению
                <span className="price-span">
                  на {formatNumberWithSpaces(totalPrice)} р
                </span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Cart;
