import React, { useContext, useEffect, useState } from "react";
import { getUser } from "../../api";

export const AppContext = React.createContext({});

export const AppProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [userInfo, setUserInfo] = useState({
    name: "",
    phone: "",
    address: "",
    comment: "",
    companyName: "",
    inn: "",
  });
  const cardStorage = localStorage.getItem("cart");
  const actualCart = JSON.parse(cardStorage);
  const userInfoStorage = localStorage.getItem("userInfo");
  const userInfoLocal = JSON.parse(userInfoStorage);

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUser();
      localStorage.setItem("userInfo", JSON.stringify(user));
      setUserInfo(user);
    };

    if (userInfoLocal) {
      setUserInfo(userInfoLocal);
    } else {
      fetchData();
    }
  }, []);

  useEffect(() => {
    // первая загрузка корзины
    if (!cart.length && actualCart && actualCart.length) {
      setCart(actualCart);
    }
    if (cart.length) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
    if (!actualCart) {
      localStorage.setItem("cart", JSON.stringify([]));
    }
  }, [cart, actualCart]);

  return (
    <AppContext.Provider
      value={{
        cart,
        setCart,
        userInfo,
        setUserInfo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useDepartment must be used within a DepartmentProvider");
  }
  return context;
};
